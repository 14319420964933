import React, { useEffect, useState} from 'react';
import { SidebarContainer, Icon, CloseIcon, IconImage, Row, InfoImage, HeroBtn, Link } from './SidebarElements';
import {useComponentVisible} from '../../../utils/useComponentVisible';
import { SidebarData } from './data';
import { useAtom, atom } from 'jotai';
import { currentPostAtom, getDistanceFormatted, currentLocationAtom, allPostsAtom } from '../../common/exploreSection/index';
import "./infoSidebar.css";
import { useParams, useNavigate } from 'react-router-dom';
import { copyToClipboard } from '../../../utils/copyToClipboard';

export const infoSidebarOpenAtom = atom(false);
export const infoSidebarOpenDelayAtom = atom(false);

const InfoSidebar = ({ 
  info={} 
}) => {
  const [currentLocation, setCurrentLocation] = useAtom(currentLocationAtom);
  const [isInfoSidebarOpen, setIsInfoSidebarOpen] = useAtom(infoSidebarOpenAtom);
  const [isInfoSidebarOpenDelay, setIsInfoSidebarOpenDelay] = useAtom(infoSidebarOpenDelayAtom);
  const [currentPost, setCurrentPost] = useAtom(currentPostAtom);
  const [allPosts, setAllPosts] = useAtom(allPostsAtom);
  const [copyText, setCopyText] = useState('Copy link');
  const [isZoomIn, setIsZoomIn] = useState(false);
  const { postId, influencerId } = useParams();
  let navigate = useNavigate();


  useEffect(() => {
    setTimeout(()=> {
      setIsInfoSidebarOpenDelay(isInfoSidebarOpen);
    }, 500);
  }, [isInfoSidebarOpen])
  
  useEffect(() => {
    if(postId) {
      if(allPosts && allPosts.length > 0) {
        const foundPosts = allPosts.filter((item) => {
          const id = item.postId;
          if(postId === id) {
            return true;
          }
          return false;
        })
        if(foundPosts && foundPosts[0]) {
          setCurrentPost(foundPosts[0]);
          setIsInfoSidebarOpen(true);
        }
      }
    }
  }, [allPosts, postId])
  

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { ref, isComponentVisible } = useComponentVisible(isInfoSidebarOpen, () => {
    if(isInfoSidebarOpen) {
      toggle();      
    }
  });

  const toggle = () => {
    setIsInfoSidebarOpen(!isInfoSidebarOpen);
    setCurrentPost(null);
    setCopyText("Copy link");

    if(influencerId) {
      navigate(`/${influencerId}`);
    }else {
      navigate(`/`);
    }
  }
  const copyLink = () => {
    copyToClipboard(window.location.href);
    setCopyText("Copied link");
  };
  const isCopied = () => copyText==="Copied link";

  return (
    <SidebarContainer isOpen={isInfoSidebarOpen}  ref={ref} style={{backgroundColor: "#141624"}}>
      <div style={{
        height: "75px",
        width: "100%",
        backgroundColor: "rgb(31 202 225)"
      }}>
        
      </div>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <div style={{
        height: "485px",
        width: "100%",
        display: "flex",
        backgroundColor: "#141624",
        justifyContent: "center"
      }}>
        <div className="post-action-group">
          <Row>
            <Link href={currentPost?.location.url} target="_blank" >
              <HeroBtn>
                <i className="fi fi-rr-marker absolute-center"></i>
                <div>{currentPost?.address}</div>
              </HeroBtn>
            </Link>
          </Row>
          <Row>
            <Link href={currentPost?.postUrl} target="_blank" >
              <HeroBtn>
                <i className="fi fi-rr-play-alt absolute-center"></i>
                <div>Watch video</div>
              </HeroBtn>
            </Link>
          </Row>
          <Row>
            <HeroBtn onClick={copyLink} style={{
              background: isCopied() ? "#ffc500" : null,
              color: isCopied() ? "#000;" : null,
            }}>
              <i className="fi fi-rr-copy-alt absolute-center"></i>
              <div>{copyText}</div>
            </HeroBtn>
          </Row>
          <Row>
            
            <div className={isZoomIn && "image-zoom-cover-container"} style={{width: '100%'}} 
              onClick={()=> {
                setIsZoomIn(!isZoomIn);
              }} >
              <div className={isZoomIn ? "image-zoom-cover" : "post-cover"}>
                <img
                  src={currentPost?.snapshotAddress}
                  alt={currentPost?.address}
                  className="collection-image"
                />
                {isZoomIn && (
                  <img 
                    src={currentPost?.thumbnail}
                    alt={currentPost?.address}
                    className="image-mini-cover"
                  />
                )}
                
                <div className="gradient-bg"></div>
                <div className="place-card-title">
                  <i className="fi fi-rr-marker absolute-center"></i>
                  <div >{currentPost?.address}</div>
                </div>
                <div className="place-card-subtitle">
                  <i class="fi fi-rr-taxi icon-distance absolute-center"></i>
                  <div className="place-distance"> {getDistanceFormatted(currentPost, currentLocation)} km</div>
                  {
                    (currentPost?.money && <>
                      <i class="fi fi-rr-money-bill-wave-alt absolute-center"></i>
                      <div className="place-distance"> {currentPost?.money}</div>
                    </>)
                  }
                </div>
              </div>
            </div>

            {/* <div class="image-zoom-cover">

            </div> */}
          </Row>

          {/* <Row>
            <i className="fi fi-rr-marker absolute-center"></i>
            <div>{currentPost.address}</div>
          </Row>
          <Row>
            <IconImage style={{ backgroundPosition: "0px -228px" }} />
            <div>Price range: {info.price}</div>
          </Row> */}
          
        </div>
      </div>
      {/* <div style={{
        bottom: 0,
        height: "85px",
        width: "100%",
        position: "absolute",
        backgroundColor: "rgb(236 44 78)"
      }}>
        
      </div> */}
    </SidebarContainer>
  );
};

export default InfoSidebar;
