import React, {useState} from "react";
import { useAtom } from 'jotai';
import { currentProfileAtom } from '../../diningOut/index';


export function IconSocial() {
  const [currentProfile, setCurrentProfile] = useAtom(currentProfileAtom);

  return (
    <div
      data-grabbing="false"
      class="profile-channel-icons"
      style={{"cursor": "grab", "user-select": "none"}}
    >
      <div class="flex">
        <a
          style={{"color": "#1C3955"}}
          href={currentProfile.social?.facebook}
          title="facebook"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="#475993"></circle>
            <path
              d="M14.078 7.44709H15.273V5.53909C15.0668 5.51309 14.3578 5.45459 13.532 5.45459C11.8091 5.45459 10.6288 6.44809 10.6288 8.27409V9.95459H8.72754V12.0876H10.6288V17.4546H12.9599V12.0881H14.7843L15.0739 9.95509H12.9594V8.48559C12.9599 7.86909 13.141 7.44709 14.078 7.44709Z"
              fill="white"
            ></path>
          </svg>
        </a>
        <a
          style={{"color": "#1C3955"}}
          href={currentProfile.social?.instagram}
          title="instagram"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="url(#icon_instagram)"></circle>
            <path
              d="M11.9996 6.55067C9.76543 6.55067 7.64386 6.35208 6.8335 8.43017C6.49878 9.28845 6.54738 10.4031 6.54738 11.9992C6.54738 13.3997 6.50247 14.7161 6.8335 15.5676C7.64201 17.6469 9.78081 17.4477 11.9984 17.4477C14.1378 17.4477 16.3437 17.6703 17.1639 15.5676C17.4992 14.7007 17.45 13.6026 17.45 11.9992C17.45 9.87069 17.5675 8.49657 16.5344 7.4649C15.4884 6.41971 14.0738 6.55067 11.9971 6.55067H11.9996ZM11.5111 7.53253C16.1714 7.52516 16.7645 7.00748 16.4372 14.199C16.3209 16.7425 14.3827 16.4634 12.0002 16.4634C7.65616 16.4634 7.53126 16.3392 7.53126 11.9967C7.53126 7.60385 7.87583 7.53499 11.5111 7.5313V7.53253ZM14.91 8.43693C14.5488 8.43693 14.2559 8.72958 14.2559 9.09048C14.2559 9.45138 14.5488 9.74403 14.91 9.74403C15.2712 9.74403 15.5641 9.45138 15.5641 9.09048C15.5641 8.72958 15.2712 8.43693 14.91 8.43693ZM11.9996 9.20115C10.4533 9.20115 9.19996 10.4541 9.19996 11.9992C9.19996 13.5442 10.4533 14.7966 11.9996 14.7966C13.5459 14.7966 14.7986 13.5442 14.7986 11.9992C14.7986 10.4541 13.5459 9.20115 11.9996 9.20115ZM11.9996 10.183C14.4024 10.183 14.4054 13.8154 11.9996 13.8154C9.59745 13.8154 9.59376 10.183 11.9996 10.183Z"
              fill="white"
            ></path>
            <defs>
              <linearGradient
                id="icon_instagram"
                x1="21.2727"
                y1="3.81818"
                x2="4.90909"
                y2="24"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#CD3AA3"></stop>
                <stop offset="0.546875" stop-color="#FB5248"></stop>
                <stop offset="1" stop-color="#FFB44F"></stop>
              </linearGradient>
            </defs>
          </svg>
        </a>
        <a
          style={{"color": "#1C3955"}}
          href={currentProfile.social?.tiktok}
          title="tiktok"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="black"></circle>
            <path
              d="M10.268 12.2204C9.3164 12.2204 8.54765 12.9891 8.56171 13.9313C8.57109 14.536 8.9039 15.061 9.39374 15.354C9.22734 15.0985 9.1289 14.7961 9.12421 14.4704C9.11015 13.5282 9.8789 12.7594 10.8305 12.7594C11.018 12.7594 11.1984 12.7899 11.3672 12.8438V10.9571C11.1914 10.9313 11.0109 10.9172 10.8305 10.9172C10.8211 10.9172 10.8141 10.9172 10.8047 10.9172V12.3047C10.6359 12.2508 10.4555 12.2204 10.268 12.2204Z"
              fill="#F00044"
            ></path>
            <path
              d="M14.3814 6.5625H14.3674H13.8799C14.0205 7.26797 14.4166 7.88203 14.9697 8.29922C14.6064 7.81875 14.3861 7.21875 14.3814 6.5625Z"
              fill="#F00044"
            ></path>
            <path
              d="M17.2498 9.43364C17.0646 9.43364 16.8865 9.41489 16.7107 9.38208V10.7344C16.0732 10.7344 15.4545 10.6102 14.8732 10.3618C14.4982 10.2024 14.149 9.99849 13.8279 9.75239L13.8373 13.9219C13.8326 14.8594 13.4623 15.7383 12.792 16.4016C12.2459 16.9407 11.5545 17.2852 10.8045 17.3954C10.6287 17.4211 10.4482 17.4352 10.2678 17.4352C9.46621 17.4352 8.70215 17.1751 8.08105 16.6946C8.15137 16.779 8.22637 16.861 8.3084 16.9407C8.9834 17.6063 9.87871 17.9743 10.8326 17.9743C11.0131 17.9743 11.1936 17.9602 11.3693 17.9344C12.1193 17.8243 12.8107 17.4797 13.3568 16.9407C14.0271 16.2774 14.3975 15.3985 14.4021 14.461L14.367 10.2915C14.6857 10.5375 15.035 10.7438 15.4123 10.9008C15.9959 11.1469 16.6146 11.2735 17.2498 11.2735"
              fill="#F00044"
            ></path>
            <path
              d="M8.30138 11.9555C8.96935 11.2922 9.85763 10.9243 10.8045 10.9172V10.418C10.6287 10.3922 10.4483 10.3782 10.2678 10.3782C9.31153 10.3782 8.41388 10.7461 7.73888 11.4165C7.0756 12.0751 6.69591 12.9751 6.69825 13.9102C6.69825 14.8524 7.07091 15.736 7.74357 16.404C7.85138 16.5094 7.96153 16.6079 8.07872 16.6969C7.54903 16.0665 7.26075 15.279 7.25841 14.4493C7.26075 13.5141 7.6381 12.6141 8.30138 11.9555Z"
              fill="#08FFF9"
            ></path>
            <path
              d="M16.7107 9.38213V8.88057H16.7061C16.0545 8.88057 15.4545 8.66494 14.9717 8.29932C15.3912 8.85244 16.0053 9.24619 16.7107 9.38213Z"
              fill="#08FFF9"
            ></path>
            <path
              d="M10.7342 16.1297C10.9568 16.1414 11.1701 16.1109 11.367 16.0477C12.0467 15.825 12.5365 15.1922 12.5365 14.4469L12.5389 11.6578V6.5625H13.8795C13.8443 6.38672 13.8256 6.20859 13.8232 6.02344H11.9764V11.1164L11.974 13.9055C11.974 14.6508 11.4842 15.2836 10.8045 15.5063C10.6076 15.5719 10.3943 15.6023 10.1717 15.5883C9.88809 15.5719 9.62324 15.4875 9.39355 15.3516C9.68184 15.7969 10.174 16.0992 10.7342 16.1297Z"
              fill="#08FFF9"
            ></path>
            <path
              d="M10.8045 17.3953C11.5545 17.2852 12.2459 16.9406 12.792 16.4016C13.4623 15.7383 13.8326 14.8594 13.8373 13.9219L13.8279 9.75234C14.1467 9.99844 14.4959 10.2047 14.8733 10.3617C15.4568 10.6078 16.0756 10.7344 16.7108 10.7344V9.38203C16.0053 9.24609 15.3912 8.85234 14.9717 8.29922C14.4186 7.88203 14.0201 7.26797 13.8818 6.5625H12.5389V11.6555L12.5365 14.4445C12.5365 15.1898 12.0467 15.8227 11.367 16.0453C11.1701 16.1109 10.9568 16.1414 10.7342 16.1273C10.1717 16.0969 9.68185 15.7945 9.39357 15.3516C8.90372 15.0609 8.57091 14.5336 8.56153 13.9289C8.54747 12.9867 9.31622 12.218 10.2678 12.218C10.4553 12.218 10.6358 12.2484 10.8045 12.3023V10.9148C9.85763 10.9219 8.96935 11.2898 8.30138 11.9531C7.6381 12.6117 7.25841 13.5117 7.26075 14.4445C7.26075 15.2742 7.54903 16.0617 8.08107 16.6922C8.7045 17.1727 9.46622 17.4328 10.2678 17.4328C10.4483 17.4352 10.6287 17.4211 10.8045 17.3953Z"
              fill="white"
            ></path>
          </svg>
        </a>
      </div>
    </div>
  );
}
