import React from "react";
import { useParams } from "react-router-dom";
import Header from "../header";
import { useAtom } from 'jotai';
import { activeTabAtom } from '../../../pages/home/index';
import "./tabOptions.css";

const tabs = [
  {
    id: 1,
    name: "Dining Out",
    active_img:
      "https://b.zmtcdn.com/data/o2_assets/30fa0a844f3ba82073e5f78c65c18b371616149662.png",
    backdrop: "#E5F3F3",
    inactive_img:
      "https://b.zmtcdn.com/data/o2_assets/78d25215ff4c1299578ed36eefd5f39d1616149985.png",
  },
  {
    id: 2,
    name: "Shopping",
    active_img:
      "/assets/online-shopping-active.png",
    backdrop: "#FCEEC0",
    inactive_img:
    "/assets/online-shopping-inactive.png",
  },
  // {
  //   id: 3,
  //   name: "Nightlife",
  //   active_img:
  //     "https://b.zmtcdn.com/data/o2_assets/855687dc64a5e06d737dae45b7f6a13b1616149818.png",
  //   backdrop: "#EDf4FF",
  //   inactive_img:
  //     "https://b.zmtcdn.com/data/o2_assets/01040767e4943c398e38e3592bb1ba8a1616150142.png",
  // },
];

const TabOptions = () => {
  const [activeTab, setActiveTab] = useAtom(activeTabAtom);

  return (
    <div className="tab-options">
      <div className="options-wrapper max-width">
        <Header />
        <div className="tabs-wrapper">
          {tabs.map((tab) => (
            <div
              className={`tab-item absolute-center cur-po ${
                activeTab === tab.name && "active-tab"
              }`}
              onClick={() => setActiveTab(tab.name)}
            >
              <div
                className="tab-image-container absolute-center"
                style={{
                  backgroundColor: `${
                    activeTab === tab.name ? tab.backdrop : ""
                  }`,
                }}
              >
                <img
                  src={activeTab === tab.name ? tab.active_img : tab.inactive_img}
                  className="tab-image"
                  alt={tab.name}
                />
              </div>
              {activeTab === tab.name && <div className="tab-name">{tab.name}</div>}
            </div>
          ))}
        </div>
        
      </div>
    </div>
  );
};

export default TabOptions;
