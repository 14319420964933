import React, {useState, useEffect} from "react";
import ExploreCard from "./exploreCard";
import { atom, useAtom } from 'jotai';
import { infoSidebarOpenAtom, infoSidebarOpenDelayAtom } from '../../sidebar/infoSidebar/index';
import "./shoppingSection.css";
import ReactList from 'react-list';
import LazyLoading from 'react-list-lazy-load';
import { useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { useScrollCloseToBottom, useScrollAtBottom } from '../../../utils/scroll';
import ReactGA from "react-ga"
import { ShoppingSearchBox, searchValueAtom, isSearchingAtom } from '../header/shoppingSearchBox';

export const currentPostAtom = atom(null);
export const currentLocationAtom = atom(null);
export const allPostsAtom = atom([]);

export const ShoppingSection = ({ list, collectionName }) => {
  const [currentLocation, setCurrentLocation] = useAtom(currentLocationAtom);
  const [searchValue, setSearchValue] = useAtom(searchValueAtom);
  const [isSearching, setIsSearching] = useAtom(isSearchingAtom);
  const [isInfoSidebarOpen, setIsInfoSidebarOpen] = useAtom(infoSidebarOpenAtom);
  const [isInfoSidebarOpenDelay, setIsInfoSidebarOpenDelay] = useAtom(infoSidebarOpenDelayAtom);
  const [currentPost, setCurrentPost] = useAtom(currentPostAtom);
  const [allPosts, setAllPosts] = useAtom(allPostsAtom);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [sliceCount, setSliceCount] = useState(8);

  const { influencerId } = useParams();
  const { height, width } = useWindowDimensions();
  

  let navigate = useNavigate();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  const openDetail = (item) => {
    if(isInfoSidebarOpenDelay && width < 600) {
      return;
    }
    const id = item.postId;
    if(id == null) {return;}

    ReactGA.event({
      category: "posts",
      action: "navigate",
      label: "navigate",
      value: influencerId
    })
    if(influencerId) {
      navigate(`/${influencerId}/${id}`);
    }else {
      navigate(`/posts/${id}`);
    }
  }
  const sortByDistance = (a,b) => getDistance(a, currentLocation) - getDistance(b, currentLocation);

  const filterOnSearchValue = (item) => {
    const text = `${item.subtitles} ${item.title}`.toLowerCase();
    const values = searchValue.toLowerCase().split(" ");
    for(const value of values) {
      if (!text.includes(value)) {
        return false;
      }
    }
    
    return true;
  };

  const filterOnDistance = (item) => {
    if(window.location.href.includes("localhost")) {
      return true;
    }

    const distance = getDistance(item, currentLocation);
    if(distance < 30) {
      return true;
    }

    return false
  }

  const getFiltedPosts = () => {
    return list
    .sort(sortByDistance)
    .filter(filterOnSearchValue)
    // .filter(filterOnDistance)
    .slice(0, sliceCount);
  }

  const isCurrentPost = (item) => {
    if(item == null || currentPost==null) {
      return false;
    }
    return item.postId===currentPost?.postId;
  }

  useEffect(() => {
    if(isSearching === true){
      setTimeout(() => {
        setIsSearching((value) => {
          if(value===true) {
            return false;
          }
          return true;
        })
      }, 2000);
    }
  }, [isSearching])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      setCurrentLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  useEffect(() => {
    setAllPosts(list);
  }, []);

  useScrollCloseToBottom(() => updateSliceCount());
  useScrollAtBottom(() => updateSliceCount());

  const updateSliceCount = () => {
    if( sliceCount < list.length ) {
      setSliceCount((count) => { return count + 4;})
    }
  }

  const placeCover = (item) => (
    <div key={item.postId}>
      <div className="place-cover">
        <a href={item.postUrl} target="_blank" rel="noreferrer">
          <img
            src={item.thumbnail}
            alt={item.title}
            className="collection-image"
          />
          <div className="gradient-bg"></div>
          <div className="shopping-card-title" style={{
            color: isCurrentPost(item) ? "rgb(193, 57, 57)" : null
          }}>
            <i className="fi fi-rr-shopping-cart-add absolute-center"></i>
            <div >{item.title}</div>
          </div>
          {/* <div className="place-card-subtitle">
            <i class="fi fi-rr-taxi icon-distance absolute-center"></i>
            <div className="place-distance"> {item.title}</div>
          </div> */}
        </a>
        
      </div>
    </div>
  )

  return (
    <div className="collection-wrapper">
      <div className="max-width explore-section">
        {/* <div className="collection-title">{collectionName}</div> */}
        <div className="place-search-box">
          <ShoppingSearchBox />
        </div>
        
        <div className="tiktok-grid">
          {getFiltedPosts()
            .map((item, index) => placeCover(item))
          }
        </div>
      </div>
    </div>
  );
};

export const getDistance = (item, currentLocation) => {
  if(currentLocation == null) return -1;
  if(item?.location == null) return -2;

  const distance = getDistanceFromLatLonInKm(item.location.lat, item.location.lng, 
    currentLocation.lat, currentLocation.lng);

  return distance;
};

export const getDistanceFormatted = (item, currentLocation) => {
  let distance = getDistance(item, currentLocation);
  if(distance === -2) {
    return "#Nearby";
  }

  distance = distance.toFixed(1);

  if(distance > 0) {
    return distance;
  }

  return "???";
};
function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

export default ShoppingSection;
