import jsonPosts from './posts.json';

export const getPosts = (infludencerId: string) => {
	if(infludencerId == null) {
		const list = [
			'food_wanderlust', 'tieudichill', 'fancywithtrang_'
		]
		let influencerPosts: any[] = [];
		for(const item of list) {
			influencerPosts = influencerPosts.concat(require(`./influencers/${item}.json`));
		}
		// return require(`./posts.json`);
		// infludencerId = "food_wanderlust";

		return influencerPosts;
	}

	const data = require(`./influencers/${infludencerId}.json`);
	return data;
};
