import React from "react";
import { restaurants } from "../../data/restaurants";
import ExploreSection from "../common/exploreSection";

import Filters from "../common/filters";

import "./delivery.css";
import { ExploreSectionv2 } from '../common/exploreSection';
import { useAtom } from 'jotai';
import { currentProfileAtom } from '../diningOut/index';
import { ShoppingSection } from '../common/shoppingSection/shoppingSection';

const Shopping = () => {
  const [currentProfile, setCurrentProfile] = useAtom(currentProfileAtom);

  const posts = [{
    "postUrl": "comming-soon",
    "thumbnail": "https://i.imgur.com/7NvHXh4.png",
    "subtitles": "",
    "address": "#Gogram #Delivery",
    "money": null,
    "location":null
  }]
  return (
    <div>
      <ShoppingSection
        list={currentProfile?.shopping ?? []}
        collectionName="Dine-Out Restaurants in Hà Nội"
      />
      {/* <DeliveryColletions />
      <TopBrands />
      <ExploreSection
        restaurants={restaurantsList}
        collectionName="Delivery Restaurants in Bangalore"
      /> */}
    </div>
  );
};

export const Delivery = () => {
  const posts = [{
    "postUrl": "comming-soon",
    "thumbnail": "https://i.imgur.com/7NvHXh4.png",
    "subtitles": "",
    "address": "#Gogram #Delivery",
    "money": null,
    "location":null
  }]
  return (
    <div>
      <ExploreSectionv2
        list={posts}
        collectionName="Dine-Out Restaurants in Hà Nội"
      />
      {/* <DeliveryColletions />
      <TopBrands />
      <ExploreSection
        restaurants={restaurantsList}
        collectionName="Delivery Restaurants in Bangalore"
      /> */}
    </div>
  );
};

export default Shopping;
