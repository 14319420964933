import React, {useState} from "react";
import "./header.css";

import { atom, useAtom } from 'jotai';
import { IconSocial } from './icon-social';
import { currentProfileAtom } from '../../diningOut/index';

export const searchValueAtom = atom('');
export const isSearchingAtom = atom(null);

export const SearchBox = () => {
  const [searchValue, setSearchValue] = useAtom(searchValueAtom);
  const [isSearching, setIsSearching] = useAtom(isSearchingAtom);

  const triggerRotate = () => {
    if(isSearching === true) {
      return;
    }
    setIsSearching(true);
  }

  return <div className="header-location-search-container">
    <div className="location-wrapper">
      <div className="location-icon-name">
        <i className="fi fi-rr-marker absolute-center location-icon"></i>
        <div>Việt Nam</div>
      </div>
      <i className="fi fi-rr-caret-down absolute-cente caret-down-icon"></i>
    </div>
    <div className="location-search-separator"></div>
    <div className="header-searchBar">
      <div onClick={() => triggerRotate()}>
        <i className={"fi fi-rr-refresh absolute-center search-icon " + (isSearching && "rotate")}></i>
      </div>
      
      <input
        className="search-input"
        placeholder="Search for restaurant, cuisine ..."
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
      />
      { searchValue!=='' && (
        <div onClick={() => setSearchValue('')} className="wrapper-cross-icon">
          <i className="fi fi-rr-cross absolute-center cross-icon"></i>
        </div>
      )}
      
    </div>
  </div>
}

const Profile = () => {
  const [currentProfile, setCurrentProfile] = useAtom(currentProfileAtom);

  return (
    <>
    <div className="profile-avatar">
        <img
            src={currentProfile.avatar}
            alt="profile-avatar"
            className="profile-avatar-image"
          />
      </div>
      <div className="profile-info">
        <div class="profile-username-wrapper">
          <h4 style={{"color":"#1C3955"}} class="profile-username">{currentProfile.primaryName}</h4>
          {
            currentProfile.secondaryName && 
            <div style={{"color":"#1C3955"}} class="profile-text">({currentProfile.secondaryName})</div>
          }
          
        </div>
        <div class="Profile_primary_text__bGfzi">
          <span width="0">
            <span>
              {
                currentProfile.email && (<>
                  <span>
                    <span>💌 Email : <a href="mailto:Nguyenhahai.neu@gmail.com" class="text-link" target="_blank">Nguyenhahai.neu@gmail.com</a>
                    </span>
                  </span>
                  <br/>
                </>)
              }
              {
                currentProfile.contactWork && (
                  <>
                    <span>
                      <span>📌Contact Work qua FB , Insta và Tiktok của Mình ⬇️</span>
                    </span>
                    <br/>
                  </>
                )
              }
             
            </span>
          </span>
        </div>
        <IconSocial />
        <div class="Profile_primary_text__bGfzi">
          <span width="0">
          {
            currentProfile.info && (
              <>
                <span>
                  <span>{currentProfile.info}</span>
                </span>
              </>
            )
          }
          </span>
        </div>
       
      </div>
    </>
  )
}

const GogramLogo = () => {
  return (
    <a href="/" className="header-link">
      <img
        src="/gogram_25.png"
        alt="Zomato-logo"
        className="header-logo"
      />
    </a> 
  )
}

const Header = () => {
  const [currentProfile, setCurrentProfile] = useAtom(currentProfileAtom);

  return (
    <div className="header max-width">
      {currentProfile?.primaryName == null ? <GogramLogo /> : <Profile /> }
      {/* */}
      
      {/* <div className="header-right">
        <SearchBox />

        <div className="profile-wrapper">
          <img
            src="https://b.zmtcdn.com/images/user_avatars/mug_2x.png?fit=around%7C100%3A100&crop=100%3A100%3B%2A%2C%2A"
            className="header-profile-image"
            alt="Profile"
          />
          <span className="header-username">Shuvam</span>
          <i className="fi fi-rr-angle-small-down absolute-center profile-options-icon"></i>
        </div>
      </div> */}
    </div>
  );
};

export default Header;
