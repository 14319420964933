import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 350px;
  height: 100%;
  background: #f0f2f5;

  align-items: center;
  top: 0;
  transition: 0.3s ease-in-out;
  right: ${({ isOpen }) => (isOpen ? '0' : '-1000px')};

  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;

export const CloseIcon = styled(FaTimes)`
  color: #1d1f23;
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  border: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const SidebarMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 80px);
  text-align: center;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(3, 60px);
  }
`;

export const HeroBtn = styled.button`
  font-size: 1.1rem;
  padding: 1rem 1rem;
  min-width: 279px;
  border: none;
  justify-content: center;
  background: rgb(236 44 78);
  color: #fff;
  transition: 0.2s ease-out;
  display: flex;
  justifyContent: center;
  gap: 8px;
  border-radius: 10px;

  &:hover {
    background: #ffc500;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: #000;
  }
`;

export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

// export const SidebarRoute = styled(Link)`
//   background: #e31837;
//   white-space: nowrap;
//   padding: 16px 64px;
//   color: #fff;
//   font-size: 16px;
//   outline: none;
//   border: none;
//   cursor: pointer;
//   transition: 0.2s ease-in-out;
//   text-decoration: none;

//   &:hover {
//     transition: 0.2s ease-in-out;
//     background: #fff;
//     color: #010606;
//   }
// `;

export const IconImage = styled.div`
  background-image: url(/assets/about.png);
  background-size: auto;
  width: 20px;
  height: 20px;
  margin-left: 6px;
  margin-right: 6px;
  background-repeat: no-repeat;
  display: inline-block;
`;

export const InfoImage = styled.div`
  background-image: url(/assets/info.png);
  background-size: auto;
  width: 20px;
  height: 20px;
  margin-left: 6px;
  margin-right: 6px;
  background-repeat: no-repeat;
  display: inline-block;
`;

export const Row = styled.div`
  display: flex;
  font-size: .9375rem;
  align-items: center;
  margin-bottom: 15px;
  justify-content: center;
`;

export const Link = styled.a`
  text-decoration: none;
`;