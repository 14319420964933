import { useEffect } from 'react';
export const scrollToBottom = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: "smooth",
    /* you can also use 'auto' behaviour
		in place of 'smooth' */
  });
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
    /* you can also use 'auto' behaviour
	in place of 'smooth' */
  });
};

export const useAutoScrollToBottom = (threshold = 300) => {
  useEffect(() => {
    let activeScroll = true;
    function autoScroll() {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > threshold && activeScroll === true) {
        scrollToBottom();
        activeScroll = false;
      }
      if (scrolled === 0) {
        activeScroll = true;
      }
    }

    window.addEventListener("scroll", autoScroll);

    return () => {
      window.removeEventListener("scroll", autoScroll);
    };
  }, []);
}

export const useScrollActive = (callback, threshold = 300) => {
  useEffect(() => {
    function autoScroll() {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > threshold) {
        callback();
        window.removeEventListener("scroll", autoScroll);
      }
    }

    window.addEventListener("scroll", autoScroll);

    return () => {
      window.removeEventListener("scroll", autoScroll);
    };
  }, []);
}

export const useScrollCloseToBottom = (callback, percent = 33) => {
  useEffect(() => {
    let activeScroll = true;

    function autoScroll() {
      const scrollBottom = document.documentElement.scrollHeight - (document.documentElement.scrollTop + document.documentElement.clientHeight);
      let threshold = document.documentElement.scrollHeight * percent/100;
      threshold = Math.min(threshold, 1058);

      if (scrollBottom < threshold && activeScroll) {
        activeScroll = false;
        callback();

        setTimeout(() => {
          activeScroll = true;
        }, 500);
      }
    }

    autoScroll();
    
    window.addEventListener("scroll", autoScroll);

    return () => {
      window.removeEventListener("scroll", autoScroll);
    };
  }, []);
}

export const useScrollAtBottom = (callback) => {
  useEffect(() => {
    let activeScroll = true;

    function autoScroll() {
      const scrollBottom = document.documentElement.scrollHeight - (document.documentElement.scrollTop + document.documentElement.clientHeight);

      if (scrollBottom === 0 && activeScroll) {
        activeScroll = false;
        callback();

        setTimeout(() => {
          activeScroll = true;
        }, 500);
      }
    }

    autoScroll();
    
    window.addEventListener("scroll", autoScroll);

    return () => {
      window.removeEventListener("scroll", autoScroll);
    };
  }, []);
}