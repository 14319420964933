import jsonPosts from './profile.json';

export const getProfile = (infludencerId: string) => {
	if(infludencerId == null) {
		// return require(`./posts.json`);
		//infludencerId = "food_wanderlust";

		return {};
	}

	return require(`./influencers/${infludencerId}.json`);
};
